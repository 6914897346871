import React, { useState } from "react"
import * as styles from "./donate.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import DonateButton from "./donate-button"
import { useStaticQuery, graphql } from "gatsby"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function DonateMain({
  image,
  alt,
  levels,
  levelSelected,
  setLevelSelected,
  donationRewardsVisible,
}) {
  const query = useStaticQuery(graphql`
    {
      paypal: file(relativePath: { eq: "paypal.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const [active, setActive] = useState(2)
  const [customAmount, setCustomAmount] = useState("")
  const [approved, setApproved] = useState(false)

  const createOrder = (data, actions) => {
    let selectedAmount = levels[active].replace(/[$,]+/g, "")

    if (customAmount !== "" && customAmount !== "$0" && customAmount !== "$") {
      selectedAmount = customAmount.replace(/[$,]+/g, "")
    }
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: selectedAmount,
          },
        },
      ],
    })
  }

  const buttonPress = (pos) => {
    setCustomAmount("")
    setLevelSelected(pos)
    setActive(pos)
  }

  const validateInput = (event) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
    })
    const original = event.target.value.replace(/\$/g, "")
    const temp = original.replace(/[,$]/g, "")
    const tempMax = temp.toString().substring(0, 7)
    const formatted = formatter.format(tempMax)

    let next = formatted
    if (next === NaN || next === "NaN") {
      next = original.substring(0, original.length - 1)
    }

    setActive(6)
    setLevelSelected(6)
    setCustomAmount(`$${next}`)
  }

  return (
    <div className={styles.donate_main}>
      <div className={styles.donate_left}>
        <div className={styles.image_overlay}>
          <GatsbyImage alt={alt} image={image} />
        </div>
        <div className={styles.donate_buttons}>
          {[...Array(6).keys()].map((num) => {
            return (
              <DonateButton
                selected={active === num}
                text={levels[num]}
                position={num}
                buttonPress={buttonPress}
              />
            )
          })}
        </div>
      </div>
      <div className={styles.donate_right}>
        <h1>Donate</h1>
        {!approved && (
          <>
            <p>
              Our goal to educate Detroit's children in a powerful place-based,
              community-based school framework requires all of us to work
              together.
            </p>
            <p>
              We come to you to ask you to help us bridge the gap between what
              this takes and what the state provides.
            </p>
            <p>
              Please consider making a tax-deductible donation to our school!
            </p>
          </>
        )}
        {approved && donationRewardsVisible && (
          <p className={styles.successful_donation}>
            Thank you for your donation!
          </p>
        )}
        {approved && !donationRewardsVisible && (
          <form
            className={styles.reward_form}
            action="/donate"
            name="Donation-Reward-Form"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input
              type="hidden"
              name="form-name"
              value="Donation-Reward-Form"
            />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            <p className={styles.successful_donation}>
              Thank you for your donation!
            </p>
            <p>
              Please enter the email address associated with your PayPal account
              so we can get your rewards to you!
            </p>
            <p>
              <label>PayPal Email</label>
              <br />
              <input name="email" />
            </p>
            <p>
              <label>What reward you would prefer</label>
              <br />
              <textarea
                name="requested_rewards"
                className={styles.large_input}
              />
            </p>
            <button className={styles.reward_button} type="submit">
              Send
            </button>
          </form>
        )}
        {!approved && (
          <div>
            <a href="https://www.paypal.com/paypalme/boggsed?country.x=US&locale.x=en_US">
              <GatsbyImage
                tabIndex={-1}
                alt="paypal"
                image={query.paypal.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default DonateMain
