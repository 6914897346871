import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { headerNames } from "../components/navigation/header"
import Donate from "../components/donate"

function DonatePage() {
  return (
    <Layout activeHeader={headerNames.donate}>
      <SEO title="Donate" />
      <Donate />
    </Layout>
  )
}

export default DonatePage
