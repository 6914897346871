import React from "react"
import * as styles from "./donate.module.scss"

function DonateButton({selected, text, position, buttonPress}) {
    let className = selected ? styles.selected : ""

    return (
        <button className={className} onClick={() => buttonPress(position)}>{text}</button>
    )
}

export default DonateButton