import React from "react"
import DonationExplanation from "./donation-explanation"
import GiftImages from "./gift-images"
import * as styles from "./donate.module.scss"

function DonationRewards({donationRewards, levelSelected}) {
  const current = donationRewards.rewards[levelSelected]

  if (!donationRewards.visible && current) {
    const { tier, tier_description, tier_image } = current

    return (
      <div className={styles.donate_rewards}>
        <DonationExplanation
          dollarAmount={tier.text}
          description={tier_description.raw}
        />
        <GiftImages currentImage={tier_image.gatsbyImageData} currentImageAltText={tier_image.alt} />
      </div>
    )
  } else {
    return <></>
  }
}

export default DonationRewards
