import React from "react"
import * as styles from "./donate.module.scss"

function DonationExplanation({dollarAmount, description}) {
  return (
    <div className={styles.donate_rewards_left}>
      <h1>{dollarAmount.text}</h1>
      {description.map((obj, index) => {
        return <p key={index}>{obj.text}</p>
      })}
    </div>
  )
}

export default DonationExplanation
