import React, { useState } from "react"
import * as styles from "./donate.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import DonationRewards from "./donation-rewards"
import DonateMain from "./donate-main"
import { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

function Donate() {
  const [levelSelected, setLevelSelected] = useState(2)
  const levels = {
    0: "$25",
    1: "$50",
    2: "$100",
    3: "$250",
    4: "$500",
    5: "$1000",
    6: "Custom",
  }

  useEffect(() => {
    const script = document.createElement("script")

    script.src =
      "https://www.paypal.com/sdk/js?client-id=AVoaIv6FtKmM2Q3oeZBVYwluxXgrxJs6mutrcehN3dBTdW74YSUpGe6BGzd0k625OyYTLDn0YdONhJzZ&currency=USD&disable-funding=card,credit"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsWeAlsoNee {
        edges {
          node {
            data {
              items {
                item {
                  text
                }
              }
              special_note {
                text
              }
              library_image {
                gatsbyImageData
                alt
              }
              supply_registry_image {
                gatsbyImageData
                alt
              }
              we_also_need_image {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
      allPrismicBoggsDonationRewards {
        edges {
          node {
            data {
              donation_main_image {
                gatsbyImageData
                alt
              }
              donation_rewards {
                text
              }
              visible
              rewards {
                tier {
                  text
                }
                tier_description {
                  raw
                }
                tier_image {
                  alt
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const donations = query.allPrismicBoggsDonationRewards.edges[0].node.data
  const weAlsoNeed = query.allPrismicBoggsWeAlsoNee.edges[0].node.data

  return (
    <div className={styles.container}>
      <section>
        <p className={styles.thanks}>WE THANK YOU!</p>
        <p className={styles.pre_thanks}>
          We could not do this without your help.
        </p>
        <DonateMain
          image={donations.donation_main_image.gatsbyImageData}
          alt={donations.donation_main_image.alt}
          levels={levels}
          donationRewardsVisible={donations.visible}
          levelSelected={levelSelected}
          setLevelSelected={setLevelSelected}
        />
        <DonationRewards
          donationRewards={donations}
          levelSelected={levelSelected}
        />
      </section>
      <section>
        <div className={styles.wishlists_container}>
          <h1>Wishlists</h1>
          <hr />
          <div className={styles.wishlists}>
            <div>
              <div className={styles.wishlist_image_container}>
                <GatsbyImage
                  className={styles.wishlist_image}
                  image={weAlsoNeed.library_image.gatsbyImageData}
                  alt={weAlsoNeed.library_image.alt}
                />
              </div>
              <h2>Library</h2>
              <p>
                If you would like to donate to our library, you can find our{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://smile.amazon.com/hz/wishlist/ls/1L79EKTXYS314?leftNavSection=SharedWithYou&ref_=abls_lnv_us"
                >
                  <p className="inline" tabIndex={0}>
                    Library Wish List here!
                  </p>
                </a>
              </p>
            </div>
            <div name={"donate#supply-registry"}>
              <div className={styles.wishlist_image_container}>
                <GatsbyImage
                  className={styles.wishlist_image}
                  image={weAlsoNeed.supply_registry_image.gatsbyImageData}
                  alt={weAlsoNeed.supply_registry_image.alt}
                />
              </div>
              <h2>Supply Registry</h2>
              <p>
                If you would like to donate supplies, you can find our{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.myregistry.com/organization/the-james-and-grace-lee-boggs-detroit-mi/4331082/giftlist"
                >
                  <p className="inline" tabIndex={0}>
                    Supply Registry here!
                  </p>
                </a>{" "}
                Items will be shipped directly to us.
              </p>
            </div>
            <div className={styles.we_also_need}>
              <div className={styles.wishlist_image_container}>
                <GatsbyImage
                  className={styles.wishlist_image}
                  image={weAlsoNeed.we_also_need_image.gatsbyImageData}
                  alt={weAlsoNeed.we_also_need_image.alt}
                />
              </div>
              <h2>We Also Need</h2>
              <ul>
                {weAlsoNeed.items.map((node, index) => {
                  return <li key={index}>{node.item.text}</li>
                })}
              </ul>
              <p>
                <i>{weAlsoNeed.special_note.text}</i>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Donate
