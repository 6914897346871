import React from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import * as styles from "./donate.module.scss"

function GiftImages({currentImage, currentImageAltText}) {
  return (
    <div className={styles.donate_rewards_right}>
      <div className={styles.donate_reward_image}>
        <GatsbyImage
          alt={currentImageAltText}
          image={currentImage}
        />
      </div>
    </div>
  );
}

export default GiftImages
