// extracted by mini-css-extract-plugin
export var container = "donate-module--container--0vJAr";
export var custom_donation = "donate-module--custom_donation--WJSLx";
export var donate_buttons = "donate-module--donate_buttons--w9EyZ";
export var donate_left = "donate-module--donate_left--qb0W6";
export var donate_main = "donate-module--donate_main--dtLhb";
export var donate_reward_image = "donate-module--donate_reward_image--foBU2";
export var donate_rewards = "donate-module--donate_rewards--nSB5e";
export var donate_rewards_left = "donate-module--donate_rewards_left--Q-Ooa";
export var donate_rewards_right = "donate-module--donate_rewards_right--MJQzN";
export var donate_right = "donate-module--donate_right--3+ekr";
export var hidden_form = "donate-module--hidden_form--vja7V";
export var image_overlay = "donate-module--image_overlay--Jtm1+";
export var large_input = "donate-module--large_input---6MLw";
export var letter = "donate-module--letter--39h4e";
export var letter_container = "donate-module--letter_container--kyyzn";
export var letter_intro = "donate-module--letter_intro--dKlq3";
export var pre_thanks = "donate-module--pre_thanks--bA5GS";
export var reward_button = "donate-module--reward_button--epy+A";
export var reward_form = "donate-module--reward_form--FdvhV";
export var selected = "donate-module--selected--frPao";
export var successful_donation = "donate-module--successful_donation--s6e7a";
export var temporary_donate_link = "donate-module--temporary_donate_link--YEqQD";
export var thanks = "donate-module--thanks--D8Cbj";
export var we_also_need = "donate-module--we_also_need--fjZFy";
export var wishlist_image = "donate-module--wishlist_image--KvPnE";
export var wishlist_image_container = "donate-module--wishlist_image_container--dcq92";
export var wishlists = "donate-module--wishlists--9aA97";
export var wishlists_container = "donate-module--wishlists_container--UX8mq";